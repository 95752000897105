@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
      @apply box-border;
    } 
  }

  .active {
    color: black;
    background-color: white;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .selected {
    /*background-color: rgb(66, 66, 66);*/
    background: radial-gradient(circle, rgba(232,255,255,0.2) 0%, rgba(0,0,0,0.8) 120%);
  }
  
  .selected-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*background-color: rgba(255, 255, 255, 0.8);*/
    padding: 5px 10px;
    border-radius: 4px;
    animation: fadeOut 1s ease-in-out forwards;
  }
  
  .fade-out {
    animation: fadeOut 1.7s ease-in-out forwards;
  }

  .carousel-container {
    overflow: hidden;
    position: relative;
}

.carousel-item {
    opacity: 0;
    position: absolute;
    top: 0;
    
    transform: translateX(100%); /* Move all non-active images to the right of the carousel container, so they are not visible*/
    transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.carousel-item.visible {
    opacity: 1;
    transform: translateX(0%); /* Move the active image to the center of the carousel container so it's visible on the screen */
    transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.carousel-item.previous {
  transform: translateX(-100%); /* Move the previous item to the left of the carousel container so it's not visible. */
}

.svg-container1:hover {
  filter: drop-shadow(0px 0px 10px #b91c1c);
}

.svg-container2:hover {
  filter: drop-shadow(0px 0px 10px #1d4ed8);
}

/* Scrollbar Styles */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

.scroll2::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #374151;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4ade80;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #16a34a;
}

.figure {
  position: relative; /* To position the ::before element absolutely within each .figure div */
}

.figure::before {
  content: attr(data-number); /* Gets the number from the data-number attribute */
  position: absolute;
  top: 8px;
  left: 8px;
  width: 24px;
  height: 24px;
  background-color: #4ade80; /* Circle color */
  color: white; /* Text color */
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  border-radius: 50%; /* Makes the element a circle */
}